import React from 'react';

const TwoDSix = () => {
    return (
        <>
            <svg data-testid="twodsix-svg" className="twodsix-svg" width="74" height="68" viewBox="0 0 74 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M29.0269 29.9926C29.4633 29.2676 30.081 28.6508 30.8392 28.2131L48.3051 18.1291C48.557 17.9837 48.8184 17.8615 49.0861 17.7627C50.2205 17.3439 51.4693 17.3439 52.6038 17.7627C52.8715 17.8615 53.1328 17.9837 53.3848 18.1291L70.8507 28.2131C71.6089 28.6508 72.2266 29.2676 72.6629 29.9926C73.1311 30.7705 73.3905 31.6729 73.3905 32.6122V52.7801C73.3905 54.5949 72.4224 56.2719 70.8507 57.1793L53.3848 67.2632C51.8131 68.1706 49.8767 68.1706 48.3051 67.2632L30.8392 57.1793C29.2675 56.2719 28.2993 54.5949 28.2993 52.7801V32.6122C28.2993 31.6729 28.5587 30.7705 29.0269 29.9926ZM70.2157 29.3129L52.7498 19.2289C51.5711 18.5484 50.1188 18.5484 48.94 19.2289L31.4741 29.3129C30.2954 29.9934 29.5692 31.2511 29.5692 32.6122V52.7801C29.5692 54.1412 30.2954 55.3989 31.4741 56.0795L48.94 66.1634C50.1188 66.844 51.5711 66.844 52.7498 66.1634L70.2157 56.0795C71.3945 55.3989 72.1206 54.1412 72.1206 52.7801V32.6122C72.1206 31.2511 71.3945 29.9934 70.2157 29.3129Z" fill="#222222"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M52.9578 43.556C51.6503 44.3109 50.0395 44.3109 48.732 43.556L48.72 43.5491L31.2786 32.4374C30.9829 32.2489 30.8959 31.8564 31.0843 31.5607C31.2727 31.2649 31.6652 31.1779 31.961 31.3663L49.3784 42.4628C50.2863 42.9816 51.4007 42.9821 52.3089 42.4642L69.3714 31.3695C69.6654 31.1783 70.0587 31.2617 70.2498 31.5557C70.441 31.8497 70.3576 32.243 70.0636 32.4342L52.9723 43.5476L52.9578 43.556Z" fill="#222222"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M51.0311 45.3893C51.3817 45.3893 51.666 45.6736 51.666 46.0243V64.5038C51.666 64.8545 51.3817 65.1388 51.0311 65.1388C50.6804 65.1388 50.3961 64.8545 50.3961 64.5038V46.0243C50.3961 45.6736 50.6804 45.3893 51.0311 45.3893Z" fill="#222222"/>
                <path d="M0.727599 13.3459C1.16396 12.621 1.78168 12.0042 2.53985 11.5664L20.0058 1.48247C20.0062 1.48222 20.0067 1.48196 20.0071 1.4817L20.7502 1.05267C21.8612 0.411235 23.23 0.411235 24.341 1.05267L25.0838 1.4815C25.0843 1.48182 25.0849 1.48215 25.0855 1.48247L42.5514 11.5664C42.552 11.5668 42.5526 11.5671 42.5531 11.5674C43.3105 12.0051 43.9276 12.6215 44.3636 13.3459C44.8318 14.1238 45.0912 15.0262 45.0912 15.9656V20.9638L43.8213 21.3799V15.9656C43.8213 14.6045 43.0952 13.3468 41.9164 12.6662L24.4505 2.58226C23.2717 1.90171 21.8195 1.90171 20.6407 2.58226L3.17481 12.6662C1.99606 13.3468 1.26992 14.6045 1.26992 15.9656V36.1335C1.26992 37.4946 1.99606 38.7523 3.17481 39.4328L20.6407 49.5168C21.8195 50.1973 23.2717 50.1973 24.4505 49.5168L28.8708 46.9647V48.4311L25.0855 50.6166C23.5138 51.524 21.5774 51.524 20.0058 50.6166L2.53985 40.5326C0.968183 39.6252 0 37.9483 0 36.1335V15.9656C0 15.0262 0.259391 14.1238 0.727599 13.3459Z" fill="#222222"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M24.6585 26.9094C23.351 27.6642 21.7402 27.6642 20.4327 26.9094L20.4207 26.9025L2.97932 15.7907C2.68356 15.6023 2.59655 15.2098 2.78498 14.914C2.9734 14.6182 3.36591 14.5312 3.66167 14.7197L21.0791 25.8162C21.9869 26.335 23.1014 26.3354 24.0096 25.8176L41.072 14.7229C41.366 14.5317 41.7593 14.615 41.9505 14.909C42.1417 15.203 42.0583 15.5963 41.7643 15.7875L24.673 26.901L24.6585 26.9094Z" fill="#222222"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.7317 28.7426C23.0824 28.7426 23.3667 29.0269 23.3667 29.3776V47.8572C23.3667 48.2078 23.0824 48.4921 22.7317 48.4921C22.3811 48.4921 22.0968 48.2078 22.0968 47.8572V29.3776C22.0968 29.0269 22.3811 28.7426 22.7317 28.7426Z" fill="#222222"/>
            </svg>
        </>
    )
}

export default TwoDSix;
import React from 'react';

const DTwelve = () => {
    return (
        <>
            <svg data-testid="dtwelve-svg" className="dtwelve-svg" width="52" height="55" viewBox="0 0 52 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M40.0811 6.01784L26.9939 1.76553C26.3479 1.55565 25.6521 1.55565 25.0061 1.76553L11.9189 6.01784C11.2729 6.22773 10.71 6.63672 10.3107 7.18621L2.22237 18.3189C1.82314 18.8684 1.60812 19.5302 1.60812 20.2094V33.9701C1.60812 34.6493 1.82314 35.3111 2.22236 35.8606L10.3107 46.9933C10.71 47.5428 11.2729 47.9518 11.9189 48.1616L25.0061 52.414C25.6521 52.6238 26.3479 52.6238 26.9939 52.414L40.0811 48.1616C40.7271 47.9518 41.29 47.5428 41.6893 46.9933L49.7776 35.8606C50.1769 35.3111 50.3919 34.6493 50.3919 33.9701V20.2094C50.3919 19.5302 50.1769 18.8684 49.7776 18.3189L41.6893 7.18621C41.29 6.63672 40.7271 6.22773 40.0811 6.01784ZM27.4908 0.236121C26.5219 -0.0787066 25.4781 -0.0787072 24.5092 0.23612L11.4219 4.48843C10.453 4.80326 9.60859 5.41675 9.00975 6.24098L0.92137 17.3737C0.322532 18.1979 0 19.1906 0 20.2094V33.9701C0 34.9889 0.322531 35.9816 0.921369 36.8058L9.00975 47.9385C9.60858 48.7627 10.453 49.3762 11.4219 49.6911L24.5092 53.9434C25.4781 54.2582 26.5219 54.2582 27.4908 53.9434L40.5781 49.6911C41.547 49.3762 42.3914 48.7627 42.9903 47.9385L51.0786 36.8058C51.6775 35.9816 52 34.9889 52 33.9701V20.2094C52 19.1906 51.6775 18.1979 51.0786 17.3737L42.9903 6.24098C42.3914 5.41675 41.547 4.80326 40.5781 4.48843L27.4908 0.236121Z" fill="#222222"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.5719 20.3672L27.8904 11.1535C26.7631 10.3345 25.2367 10.3345 24.1094 11.1535L11.4279 20.3672C10.3006 21.1862 9.82894 22.6379 10.2595 23.9631L15.1034 38.8712C15.534 40.1963 16.7689 41.0935 18.1623 41.0935H33.8375C35.2309 41.0935 36.4658 40.1963 36.8964 38.8712L41.7403 23.9631C42.1709 22.6379 41.6992 21.1862 40.5719 20.3672ZM28.8356 9.85255C27.1447 8.62405 24.8551 8.62405 23.1642 9.85255L10.4827 19.0662C8.79178 20.2947 8.08425 22.4723 8.7301 24.46L13.574 39.3681C14.2199 41.3559 16.0722 42.7017 18.1623 42.7017H33.8375C35.9276 42.7017 37.7799 41.3559 38.4258 39.3681L43.2697 24.46C43.9156 22.4723 43.208 20.2947 41.5172 19.0662L28.8356 9.85255Z" fill="#222222"/>
                <line x1="25.696" y1="1.35984" x2="25.696" y2="9.40044" stroke="#222222"/>
                <line x1="36.0777" y1="41.3056" x2="40.902" y2="49.3461" stroke="#222222"/>
                <line y1="-0.5" x2="9.37686" y2="-0.5" transform="matrix(-0.514496 0.857493 0.857493 0.514496 16.3511 41.5628)" stroke="#222222"/>
                <line x1="8.94374" y1="21.1271" x2="0.783595" y2="18.1571" stroke="#222222"/>
                <line y1="-0.5" x2="8.68384" y2="-0.5" transform="matrix(0.939693 -0.34202 -0.34202 -0.939693 42.0811 21.4613)" stroke="#222222"/>
            </svg>
        </>
    );
}

export default DTwelve;